import WebsiteDefaultPage from '../components/Page/Base';
import { LIGHT_DARK } from '../constants';
import { Color } from '../styles';
import { CommunicationPolicy } from '../components/PageSpecific/legal';

import React from 'react';

const Communications = () => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <CommunicationPolicy />
    </WebsiteDefaultPage>
  );
};

export default Communications;
